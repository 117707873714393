<!--
Edit form for GridHeaterSchema (many=true)
TODO: handle validation of share and notify parent on error #refactoring
-->
<i18n>
{
  "de": {
    "heatingTypeTitle": "Typ",
    "shareTitle": "Anteil",
    "energyCalibration": "Endenergie Kalibrierung",
    "addHeatingButton": "Wärmeerzeuger hinzufügen",
    "noHeatingDefined": "Keine Wärmeerzeuger",
    "totalShareWarning": "Summe Anteil muss exakt 100% betragen!",
    "total": "Total",
    "shareInfoBoxTitle": "Anteil",
    "shareInfoBox": "Anteil der Wärme, der durch diesen Wärmeerzeuger bereitgestellt wird."
  }
}
</i18n>

<template>
  <div class="edit-grid-heaters-table">
    <DetailList v-if="model.length > 0" has-header :has-footer="model.length > 1" class="heating-table">
      <template #header>
        <span>{{ $t('heatingTypeTitle') }}</span>
        <span class="align-right">
          <span>{{ $t('shareTitle') }}</span
          ><InfoBox :title="$t('shareInfoBoxTitle')">{{ $t('shareInfoBox') }}</InfoBox>
        </span>
        <!-- <span class="align-right">{{ $t('endEnergyTitle') }} <InfoBox :title="$t('endEnergyInfoBoxTitle')">{{ $t('endEnergyInfoBox') }}</InfoBox></span> -->
        <!-- <span class="align-right">{{ $t('energyCalibration') }} <InfoBox :title="$t('calibrationInfoBoxTitle')">{{ $t('calibrationInfoBox') }}</InfoBox></span> -->
        <!-- <span class="align-right">{{ $t('energyCalibrationFactor') }}</span> -->
        <span v-if="isEditing"></span>
      </template>

      <li v-for="(h, index) in model" :key="index">
        <HeatingTypePicker
          v-model="h.heating_type_id"
          :exclude="['LOCAL']"
          :portfolio="portfolio"
          :edit="isEditing"
          @input="onInput"
        />

        <div class="align-right">
          <NumericInput v-model="h.share" :min="0" :max="100" :edit="isEditing" :units="'%'" @input="onInput" />
        </div>

        <!-- <div class="align-right">
          <span v-if="getEndEnergy(index)">
            {{ formatNumber(getEndEnergy(index), -2) }} kWh
          </span>
          <span v-else>-</span>
        </div> -->
        <!-- <div class="align-right">
          <NumericInput @input="onInput" v-model="h.energy_calibration" optional :min="0" :edit="isEditing" :units="'kWh'" />
        </div> -->
        <!--
        <div class="align-right">
          <span v-if="getCalibrationFactor(index) && h.energy_calibration">
            <span v-if="getCalibrationFactor(index) >= 1.0">
              <DataWarning v-if="getCalibrationFactor(index) > 1.667" :title="$t('highCalibrationFactorWarningTitle')"><span v-html="$t('highCalibrationFactorWarning')" /></DataWarning>
              +{{ formatNumber(getCalibrationFactor(index) * 100 - 100, 1) }} %
            </span>
            <span v-else>
              <DataWarning v-if="getCalibrationFactor(index) < 0.6" :title="$t('lowCalibrationFactorWarningTitle')"><span v-html="$t('lowCalibrationFactorWarning')" /></DataWarning>
              {{ formatNumber(getCalibrationFactor(index) * 100 - 100, 1) }} %
            </span>
          </span>
          <span v-else>-</span>
        </div>
        -->

        <template v-if="isEditing">
          <ListButtonWrapper>
            <ListDeleteButton @click="onDeleteHeating(index)" />
          </ListButtonWrapper>
        </template>
      </li>

      <template #footer>
        <span>{{ $t('total') }}</span>
        <span class="align-right">{{ totalShare }} %</span>
        <span></span>
        <span></span>
        <span v-if="isEditing"></span>
      </template>
    </DetailList>

    <div v-else>
      {{ $t('noHeatingDefined') }}
    </div>

    <div v-if="model.length > 0 && totalShare !== 100" class="sum-warning">
      <p>{{ $t('totalShareWarning') }}</p>
    </div>

    <ButtonWrapper>
      <Button v-if="isEditing" icon="plus" :text="$t('addHeatingButton')" @click="onAddHeating" />
    </ButtonWrapper>
  </div>
</template>

<script>
import ComplexFormMixin from '@/components/shared/forms/ComplexFormMixin.vue'

import ListButtonWrapper from '@/components/shared/lists/ListButtonWrapper.vue'
import ListDeleteButton from '@/components/shared/lists/ListDeleteButton.vue'
import ButtonWrapper from '@/components/shared/ButtonWrapper.vue'
import HeatingTypePicker from '@/components/building/edit/HeatingTypePicker.vue'
import DetailList from '@/components/shared/lists/DetailList.vue'
import NumericInput from '@/components/shared/forms/NumericInput.vue'
// import DataWarning from '@/components/shared/DataWarning.vue'
import InfoBox from '@/components/shared/InfoBox.vue'
import Button from '@/components/cui/inputs/Button.vue'

export default {
  mixins: [
    //
    ComplexFormMixin,
  ],

  components: {
    ListDeleteButton,
    ListButtonWrapper,
    ButtonWrapper,
    NumericInput,
    DetailList,
    InfoBox,
    // DataWarning,
    HeatingTypePicker,
    Button,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    heatersResults: {
      type: Array,
    },
  },

  computed: {
    // Overrides mixin function
    isValid() {
      return this.noInvalidFields && (this.model.length === 0 || this.totalShare === 100)
    },

    totalShare() {
      return this.model.reduce((pv, cv) => pv + cv.share, 0)
    },

    /* totalEndEnergy () {
      if (this.heatersResults) {
        return this.heatersResults.reduce((pv, cv) => pv + cv.rh_end_energy + cv.dhw_end_energy, 0)
      }
      return undefined
    }, */

    totalCalibration() {
      return this.model.reduce((pv, cv) => pv + cv.energy_calibration, 0)
    },
  },

  methods: {
    /* getCalibrationFactor (heaterIdx) {
      if (this.heatersResults && this.heatersResults[heaterIdx] !== undefined && this.heatersResults[heaterIdx].calibration_factor !== null) {
        return this.heatersResults[heaterIdx].calibration_factor
      }
      return undefined
    },

    getEndEnergy (heaterIdx) {
      if (this.heatersResults && this.heatersResults[heaterIdx] !== undefined && this.heatersResults[heaterIdx].rh_end_energy !== null) {
        return (this.heatersResults[heaterIdx].rh_end_energy + this.heatersResults[heaterIdx].dhw_end_energy)
      }
      return undefined
    }, */

    onAddHeating() {
      this.model.push({
        heating_type_id: 'UNKNOWN',
        share: this.model.length === 0 ? 100 : 0,
        ghg_factor: null,
        pe_factor: null,
        energy_calibration: null,
      })
      this.onInput()
    },

    onDeleteHeating(index) {
      this.model.splice(index, 1)
      this.onInput()
    },
  },
}
</script>

<style lang="scss">
.edit-grid-heaters-table {
  & .detail-list > ul > li {
    display: grid;
    grid-template-columns: 2fr 1fr 50px;
  }

  & .sum-warning {
    color: var(--warning-color);
    padding: var(--box-padding);
  }

  & .align-right {
    text-align: right;
    justify-content: flex-end;
  }

  & .source-info {
    color: var(--secondary-text-color);
    font-size: var(--font-xs);
    line-height: 16px;
  }
}
</style>
